import React, { Fragment } from 'react';
import { isEmptyString } from '../../../../utils/generalFunctions';

// chakra
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Heading,
  Text,
  Button,
  Container,
  Image,
  Stack,
  Flex,
  Divider,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Progress,
  RadioGroup,
  Radio,
  Textarea,
  Box,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  List,
  ListItem,
  ListIcon,
  OrderedList,
  UnorderedList,
} from '@chakra-ui/react';

const HomebuyerStepSix = ({
  setActiveStep,
  stepFiveCoApplicant,
  stepSixMonthlyIncome,
  setStepSixMonthlyIncome,
  stepSixRent,
  setStepSixRent,
  stepSixHomePreferredPrice,
  setStepSixHomePreferredPrice,
  stepSixDownPaymentSavings,
  setStepSixDownPaymentSavings,
  stepSixCoApplicantMonthlyIncome,
  setStepSixCoApplicantMonthlyIncome,
  handleNext,
  loading,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenPreferredPrice,
    onOpen: onOpenPreferredPrice,
    onClose: onClosePreferredPrice,
  } = useDisclosure();
  const {
    isOpen: isOpenBoth,
    onOpen: onOpenBoth,
    onClose: onCloseBoth,
  } = useDisclosure();

  const format = val => `$` + val;
  const parse = val => val.replace(/^\$/, '');

  return (
    <Fragment>
      <Stack spacing="5">
        <Progress
          colorScheme="blue"
          rounded="full"
          size="md"
          value={(6 / 18) * 100}
          bg="gray.200"
          hasStripe
          isAnimated
        />
        <Card bg="white" size="lg">
          <CardBody>
            <Stack spacing="8">
              <Box>
                <Box mb="5">
                  <Heading as="h4" size="md" mb="1">
                    Please share your financial situation:
                  </Heading>
                  <Text fontSize="md" fontWeight="600" color="gray">
                    We will use this information to calculate your potential
                    budget. Include all types of income for a more accurate
                    result.
                  </Text>
                </Box>

                <Stack spacing="5">
                  <FormControl>
                    <FormLabel color="gray">
                      Your gross monthly income:
                    </FormLabel>
                    <NumberInput
                      size="lg"
                      value={format(stepSixMonthlyIncome)}
                      onChange={valueString =>
                        setStepSixMonthlyIncome(parse(valueString))
                      }
                      min={0}
                    >
                      <NumberInputField borderWidth="2px" size="lg" />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                    <FormHelperText color="gray.400">
                      Enter pre-tax income
                    </FormHelperText>
                  </FormControl>

                  {stepFiveCoApplicant === 'yes' && (
                    <FormControl>
                      <FormLabel color="gray">
                        Co-Buyer gross monthly income:
                      </FormLabel>
                      <NumberInput
                        size="lg"
                        value={format(stepSixCoApplicantMonthlyIncome)}
                        onChange={valueString =>
                          setStepSixCoApplicantMonthlyIncome(parse(valueString))
                        }
                        min={0}
                      >
                        <NumberInputField borderWidth="2px" size="lg" />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                      <FormHelperText color="gray.400">
                        Enter pre-tax income
                      </FormHelperText>
                    </FormControl>
                  )}

                  <FormControl>
                    <FormLabel color="gray">Your rent right now:</FormLabel>
                    <NumberInput
                      size="lg"
                      value={format(stepSixRent)}
                      onChange={valueString =>
                        setStepSixRent(parse(valueString))
                      }
                      min={0}
                    >
                      <NumberInputField borderWidth="2px" size="lg" />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                    <FormHelperText color="gray.400">
                      Enter 0 if you are not renting at the moment
                    </FormHelperText>
                  </FormControl>

                  <FormControl>
                    <FormLabel color="gray">
                      Your preferred price for your future home:
                    </FormLabel>
                    <NumberInput
                      size="lg"
                      value={format(stepSixHomePreferredPrice)}
                      onChange={valueString =>
                        setStepSixHomePreferredPrice(parse(valueString))
                      }
                      min={0}
                    >
                      <NumberInputField borderWidth="2px" size="lg" />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                    <FormHelperText color="gray.400">
                      Enter the home price that you are comfortable with
                    </FormHelperText>
                  </FormControl>

                  <FormControl>
                    <FormLabel color="gray">
                      {stepFiveCoApplicant === 'yes'
                        ? 'Your and the Co-Buyer’s'
                        : 'Your'}{' '}
                      available total savings for a down payment:
                    </FormLabel>
                    <NumberInput
                      size="lg"
                      value={format(stepSixDownPaymentSavings)}
                      onChange={valueString =>
                        setStepSixDownPaymentSavings(parse(valueString))
                      }
                      min={0}
                    >
                      <NumberInputField borderWidth="2px" size="lg" />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                    <FormHelperText color="gray.400">
                      You should be in a position to put down 1-3% of Home Price
                    </FormHelperText>
                  </FormControl>
                </Stack>
              </Box>
            </Stack>
          </CardBody>
        </Card>

        <Stack justify="right" direction="row" spacing="3">
          <Button
            onClick={() => {
              setActiveStep(5);
            }}
          >
            Prev
          </Button>

          {stepFiveCoApplicant === 'yes' ? (
            <Button
              colorScheme="blue"
              onClick={() => {
                if (
                  parseFloat(stepSixMonthlyIncome) +
                    parseFloat(stepSixCoApplicantMonthlyIncome) <
                    4500 &&
                  (parseFloat(stepSixHomePreferredPrice) < 175000 ||
                    parseFloat(stepSixHomePreferredPrice) > 1000000)
                ) {
                  onOpenBoth();
                } else if (
                  parseFloat(stepSixMonthlyIncome) +
                    parseFloat(stepSixCoApplicantMonthlyIncome) <
                  4500
                ) {
                  onOpen();
                } else if (
                  parseFloat(stepSixHomePreferredPrice) < 175000 ||
                  parseFloat(stepSixHomePreferredPrice) > 1000000
                ) {
                  onOpenPreferredPrice();
                } else {
                  handleNext();
                }
              }}
              isDisabled={
                isEmptyString(stepSixMonthlyIncome) ||
                isEmptyString(stepSixRent) ||
                isEmptyString(stepSixHomePreferredPrice) ||
                isEmptyString(stepSixDownPaymentSavings) ||
                isEmptyString(stepSixCoApplicantMonthlyIncome)
              }
            >
              {loading ? <Spinner /> : 'Next'}
            </Button>
          ) : (
            <Button
              colorScheme="blue"
              onClick={() => {
                if (
                  parseFloat(stepSixMonthlyIncome) < 4500 &&
                  (parseFloat(stepSixHomePreferredPrice) < 175000 ||
                    parseFloat(stepSixHomePreferredPrice) > 1000000)
                ) {
                  onOpenBoth();
                } else if (parseFloat(stepSixMonthlyIncome) < 4500) {
                  onOpen();
                } else if (
                  parseFloat(stepSixHomePreferredPrice) < 175000 ||
                  parseFloat(stepSixHomePreferredPrice) > 1000000
                ) {
                  onOpenPreferredPrice();
                } else {
                  handleNext();
                }
              }}
              isDisabled={
                isEmptyString(stepSixMonthlyIncome) ||
                isEmptyString(stepSixRent) ||
                isEmptyString(stepSixHomePreferredPrice) ||
                isEmptyString(stepSixDownPaymentSavings)
              }
            >
              {loading ? <Spinner /> : 'Next'}
            </Button>
          )}
        </Stack>
      </Stack>

      <Modal size="md" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px) " />
        <ModalContent>
          <ModalHeader>
            <Text fontWeight="700" fontSize="xl">
              Oops!
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing="5">
              {stepFiveCoApplicant === 'no' ? (
                <Text fontWeight="500" fontSize="md" textAlign={'justify'}>
                  At this point of time, we are taking in applicants with
                  monthly household income of{' '}
                  <span style={{ fontWeight: 800 }}>$4,500</span>. Please apply
                  if you meet this criteria. Thank you for your consideration.
                </Text>
              ) : (
                <Text fontWeight="500" fontSize="md" textAlign={'justify'}>
                  At this point of time, we are taking in applicants whose
                  combined applicant and co-buyer monthly household income is at
                  or greater than{' '}
                  <span style={{ fontWeight: 800 }}>$4,500</span>. Please apply
                  if you meet this criteria. Thank you for your consideration.
                </Text>
              )}
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="gray"
              onClick={() => {
                onClose();
              }}
              mr="4"
            >
              Close
            </Button>
            {/* <Button
              colorScheme="blue"
              onClick={() => {
                onCloseBackgroundModal()
                setStep(step + 1)
              }}
            >
              I Agree
            </Button> */}
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        size="md"
        isOpen={isOpenPreferredPrice}
        onClose={onClosePreferredPrice}
      >
        <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px) " />
        <ModalContent>
          <ModalHeader>
            <Text fontWeight="700" fontSize="xl">
              Oops!
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing="5">
              <Text fontWeight="500" fontSize="md" textAlign={'justify'}>
                At this point of time, we are working with applicants who prefer
                their future homes in the price range of{' '}
                <span style={{ fontWeight: 800 }}>$175,000 and $1,000,000</span>
                .
              </Text>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="gray"
              onClick={() => {
                onClosePreferredPrice();
              }}
              mr="4"
            >
              Close
            </Button>
            {/* <Button
              colorScheme="blue"
              onClick={() => {
                onCloseBackgroundModal()
                setStep(step + 1)
              }}
            >
              I Agree
            </Button> */}
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal size="md" isOpen={isOpenBoth} onClose={onCloseBoth}>
        <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px) " />
        <ModalContent>
          <ModalHeader>
            <Text fontWeight="700" fontSize="xl">
              Oops!
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing="5">
              <Text fontWeight="500" fontSize="lg" textAlign={'justify'}>
                At this point of time
              </Text>
              <UnorderedList spacing="5" px="4">
                <ListItem>
                  {stepFiveCoApplicant === 'no' ? (
                    <Text fontWeight="500" fontSize="md" textAlign={'justify'}>
                      We are taking in applicants with monthly household income
                      of <span style={{ fontWeight: 800 }}>$4,500</span>. Please
                      apply if you meet this criteria. Thank you for your
                      consideration.
                    </Text>
                  ) : (
                    <Text fontWeight="500" fontSize="md" textAlign={'justify'}>
                      At this point of time, we are taking in applicants whose
                      combined applicant and co-buyer monthly household income
                      is at or greater than{' '}
                      <span style={{ fontWeight: 800 }}>$4,500</span>. Please
                      apply if you meet this criteria. Thank you for your
                      consideration.
                    </Text>
                  )}
                </ListItem>
                <ListItem>
                  <Text fontWeight="500" fontSize="md" textAlign={'justify'}>
                    We are working with applicants who prefer their future homes
                    in the price range of{' '}
                    <span style={{ fontWeight: 800 }}>
                      $175,000 and $1,000,000
                    </span>
                    .
                  </Text>
                </ListItem>
              </UnorderedList>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="gray"
              onClick={() => {
                onCloseBoth();
              }}
              mr="4"
            >
              Close
            </Button>
            {/* <Button
              colorScheme="blue"
              onClick={() => {
                onCloseBackgroundModal()
                setStep(step + 1)
              }}
            >
              I Agree
            </Button> */}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Fragment>
  );
};

export default HomebuyerStepSix;
